const humanizeString = require('humanize-string');
// const lunr = require('lunr-chinese');

$(document).ready( function () {
  navbarActiveToggle();
  $("#search-btn").click(function(event){
    event.preventDefault();
    var keyword = $("#search-input").val() || ""
    // console.log($("#search-input").val())
    if (keyword != "") {
      // search
      getIndexData(keyword);
      // hide 
    }
  })

  var qrcode = document.getElementById('qrcode');
  // console.log('qrcode:',qrcode);
  if (qrcode !== null) {
    generateQRCode(qrcode);
  }
  // testQuery();
  // console.log(humanizeString('/golang/golang-better-repos'))
})

function getIndexData(query) {
  fetch('/data/index.json')
    .then(function (response) {
      return response.json();
    })
    .then(function (jsonData) {
      lunrSearch(jsonData, query);
    })
    .catch(function (err) {
      console.log(err);
    });
}

function lunrSearch(jsonData, query) {
  // console.log(jsonData);
  var idx = lunr(function () {
    this.ref('uri')

    this.field('content')

    jsonData.forEach(function (doc) {
      this.add(doc)
    }, this)
  })  
  // get data from net
  var result = idx.search(query)
  // console.log(result);
  // render UI use data
  renderSearchResults(result);
}

function humanizeTitle(title) {
  title = humanizeString(title.split('/').pop())
  return title
}

function renderSearchResults(result) {
  var $search = $(".main");
  
  $search.empty();
  $search.prepend('<h2 class="search-results">Search Results:<h2>');
  result.forEach(elem => {
    link = `<a href="${elem.ref}" target="_blank">${ humanizeTitle(elem.ref) }</a><br>`;
    $search.append(link);
  });

}


// storeIndexData stores index.json to IndexedDB
function storeIndexData(data) {
  // Define your database
  var db = new Dexie("posts");
  db.version(2).stores({
    posts: 'uri, content, tags'
  });
  // Put some data into it
  data.forEach(elem => {
    db.posts.put(elem)
      .catch(err => {
        console.log(err);
      })
  });
}

// read index.json data from indexedDB
function readIndexFromDb() {}

// query from indexedDB
function testQuery() {
  new Dexie('posts').open().then(function (db) {
    console.log("Found database: " + db.name);
    console.log("Database version: " + db.verno);
    db.tables.forEach(function (table) {
      console.log("Found table: " + table.name);
      console.log("Table Schema: " +
        JSON.stringify(table.schema, null, 4));
    });
  }).catch('NoSuchDatabaseError', function (e) {
    // Database with that name did not exist
    console.error("Database not found");
  }).catch(function (e) {
    console.error("Oh uh: " + e);
  });
}

// navbar active status toggle
function navbarActiveToggle() {
  var navStorage = window.localStorage;
  $(".mr-auto .nav-item").bind("click", function (event) {
    event.stopPropagation();
    var clickedItem = $(this);
    navStorage.setItem('CilckedTab', clickedItem.attr('id'));
  });
  $(".navbar-brand").click(function (event) {
    event.stopPropagation();
    navStorage.setItem('CilckedTab', null);
  });
  var clickedItemId = navStorage.getItem('CilckedTab');
  // remove all active in links
  if (clickedItemId == null) {
    $(".mr-auto .nav-item").removeAttr('active');
  } else {
    var $lastClickedItem = $("#" + clickedItemId);
    $lastClickedItem.addClass('active');
  }
}

// generate article url's QR Code
function generateQRCode(element) {
  var qrcode = new QRCode(element, {
    width: 100,
    height: 100
  });
  
  qrcode.makeCode(document.URL);
}